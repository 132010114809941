<i18n>
{
    "ru": {
        "segment": "Сегмент",
        "rating": "Рейтинг",
        "averagePrice": "Средняя цена",
        "price_tooltip": "Средняя официальная стоимость флакона объёмом 50 мл. Цена в долларах актуальна для ароматов, не представленных в российских магазинах.",
        "country": "Страна",
        "set_country_name": "Введите название страны...", 
        "under-500-rub": "До 500 ₽",
        "500-2000-rub": "500 – 2 000 ₽",
        "2000-5000-rub": "2 000 – 5 000 ₽",
        "5000-17000-rub": "5 000 – 17 000 ₽",
        "17000-30000-rub": "17 000 – 30 000 ₽",
        "over-30000-rub": "Свыше 30 000 ₽",
        "under-25-usd": "До 25 $",
        "25-70-usd": "25 – 70 $",
        "70-170-usd": "70 – 170 $",
        "170-350-usd": "170 – 350 $",
        "over-350-usd": "Свыше 350 $"
    }   
}
</i18n>
<template>
    <CatalogFilter class="brands-filter"
        ref="filter"
        @resetAll="resetAll"
        @submit="$emit('submit', $event)">
        <template #body>

            <CatFilterBlock v-if="fields.ratings"
                :title="$t('rating')"
                :counter="values.rating.length"
                :is-opened="true"
                @reset="reset('rating')">
                <template #block-body>
                    <div class="brands-filter__checkboxes">
                        <div v-for="value in fields.ratings"
                            :key="value.code"
                            class="checkbox cat-filter-block__checkbox">
                            <input v-model="values.rating" 
                                type="checkbox"
                                :id="'rating_' + value.code"
                                :value="value.code" />
                            <label :for="'rating_' + value.code">
                                <html-link v-if="value.htmlUrl"
                                    :url="value.htmlUrl">
                                    {{ $options.filters.numberDeclension(value.name, $t('declensions.stars')) }}
                                </html-link>
                                <div v-else
                                    class="rating cat-filter-block__rating-stars"
                                    :data-value="value.code"></div>
                            </label>
                        </div>
                    </div>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.segments"
                :title="$t('segment')"
                :counter="values.segment.length"
                :is-opened="isDeviceDesktop"
                @reset="reset('segment')">
                <template #block-body>
                    <div class="brands-filter__checkboxes">
                        <div v-for="value in fields.segments"
                            :key="value.code"
                            class="checkbox cat-filter-block__checkbox">
                            <input v-model="values.segment" 
                                type="checkbox"
                                :id="'segment_' + value.code"
                                :value="value.code" />
                            <label :for="'segment_' + value.code">
                                <html-link v-if="value.htmlUrl"
                                    :url="value.htmlUrl">
                                    {{ value.name }}
                                </html-link>
                                <template v-else>{{ value.name }}</template>
                            </label>
                        </div>
                    </div>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.averagePrices"
                :title="$t('averagePrice')"
                :tooltip="$t('price_tooltip')"
                :counter="values.average_price.length"
                :is-opened="isDeviceDesktop"
                @reset="reset('averagePrice')">
                <template #block-body>
                    <div class="brands-filter__checkboxes">
                        <div v-for="value in fields.averagePrices"
                            :key="value.code"
                            class="checkbox cat-filter-block__checkbox">
                            <input v-model="values.average_price" 
                                type="checkbox"
                                :id="'price_' + value.code"
                                :value="value.code" />
                            <label :for="'price_' + value.code">
                                <html-link v-if="value.htmlUrl"
                                    :url="value.htmlUrl">
                                    {{ $t(value.name) }}
                                </html-link>
                                <template v-else>{{ $t(value.name) }}</template>
                            </label>
                        </div>
                    </div>
                </template>
            </CatFilterBlock>

            <CatFilterBlock v-if="fields.countries"
                :title="$t('country')"
                :counter="values.country.length"
                :is-opened="isDeviceDesktop"
                @reset="reset('country')">
                <template #block-body>

                    <SearchField
                        class="brands-filter__search"
                        ref="countriesSearch"
                        v-model="countryQuery"
                        :placeholder="$t('set_country_name')" />

                    <PaginatedList
                        :list="fields.countries"
                        :q="countryQuery"
                        :columns-count="1"
                        :list-classes="{columns_checkboxes: true}"
                        :force-faded="true"
                        @loaded="$refs.countriesSearch.loading = false"
                        class="brands-filter__list">

                        <template v-slot:item="value">
                            <div class="checkbox">
                                <input v-model="values.country" 
                                    type="checkbox"
                                    :id="'country_' + value.code"
                                    :value="value.code" />
                                <label :for="'country_' + value.code">
                                    <html-link v-if="value.htmlUrl"
                                        :url="value.htmlUrl">
                                        {{ value.name }}
                                    </html-link>
                                    <template v-else>{{ value.name }}</template>
                                </label>
                            </div>
                        </template>

                    </PaginatedList>
                </template>
            </CatFilterBlock>

        </template>
    </CatalogFilter>
</template>

<script>
import PaginatedList from '@/components/ui/PaginatedList';
import SearchField from '@/components/layout/search/SearchField';

import FilterMixin from '@/components/catalog/mixins/Filter.mixin';
import CatalogFilter from '@/components/catalog/filters/CatalogFilter';
import CatFilterBlock from '@/components/catalog/filters/parts/CatFilterBlock';

import Brand from '@/models/catalog/brand';

export default {
    name: 'BrandsFilter',
    components: {
        CatalogFilter,
        CatFilterBlock,
        PaginatedList,
        SearchField
    },
    mixins: [FilterMixin],
    data() {
        return {
            values: {
                segment: [],
                rating: [],
                average_price: [],
                country: []
            },
            q: null,
            countryQuery: null,
            model: new Brand,

            countDeclensions: this.$t('declensions.brands'),
            stopEvents: {
                changed: true
            }
        }
    },
}
</script>

<style lang="less">
.brands-filter{

    &__checkboxes{
        margin-top: rem(-19);
    }

    &__search{
        margin-top: rem(-4);
    }
    
    &__list{
        &.paginated-list{
            height: rem(188);
        }
        margin-top: rem(20);
    }

    &__checkbox-picture{
        padding-top: rem(7);
    }
}
</style>