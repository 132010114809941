<template>
    <div
        class="block-shop__wrapper"
        :class="rootClasses">
        <div class="block-shop__picture-wrapper"
            :class="{
                'block-shop__picture-wrapper_small': contentWide,
                'block-shop__picture-wrapper_empty': !picture
            }">
            <img
                class="block-shop__picture"
                v-lazy-load :data-src="picture ? picture : getEntityNoImage('brand')"
                alt="" />
        </div>
        <div :class="innerWrapperClasses" class="block-shop__inner-wrapper">
            <div class="block-shop__header" :class="headerClasses">
                <div class="block-shop__name">
                    {{ name }}
                </div>
                <div
                    v-if="withRating && computedRating"
                    :data-value="computedRating"
                    class="rating block-shop__rating"></div>
            </div>
            <div class="block-shop__content">
                <div v-if="!isDeviceDesktop && description" class="block-shop__description">
                    {{ description }}
                </div>
                <div class="block-shop__caption">
                    {{ caption }}
                </div>

                <div
                    v-if="withRatingBottom && computedRating"
                    :data-value="computedRating"
                    class="rating block-shop__rating"></div>

                <div v-if="withComment && commentsCount != null" class="block-shop__comments">
                    <svg-icon
                        name="icon-message-circle"
                        class="block-shop__comments-count-icon icon__message" />
                    <span class="block-shop__comments-caption">
                        {{ commentsCount || 0 }} {{ commentsCaption ? commentsCaption : '' }}
                    </span>
                </div>
            </div>
            <html-link
                v-if="url"
                :url="url"
                class="block-shop__link"
                :aria-label="name" />
            <html-link
                v-else-if="routeTo"
                :to="routeTo"
                class="block-shop__link"
                :aria-label="name" />
            <a v-else-if="link"
                rel="nofollow"
                target="_blank"
                :href="link"
                :aria-label="name"
                class="block-shop__link"></a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OfferMixin from '@/mixins/catalog/offers.mixin'

export default {
    name: 'BlockShop',
    mixins: [ OfferMixin ],
    props: {
        contentWide: {
            type: Boolean,
            default: false
        },
        direction: {
            type: String,
            default: 'row'
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        innerWrapperClasses() {
            return {
                'block-shop__inner-wrapper_content-wide': this.contentWide,
                'block-shop__inner-wrapper_content-centered': this.centeredContent
            }
        },
        rootClasses() {
            return {
                'block-shop__wrapper_column': this.direction === 'column'
            }
        },
        headerClasses() {
            return {
                'block-shop__header_column': this.direction === 'column'
            }
        }
    }
};
</script>

<style lang="less">
.block-shop {
    &__wrapper {
        --desktop-width: rem(170);
        --picture-wrapper-width: rem(100);
        --picture-height: rem(56);
        display: flex;
        justify-content: space-between;

        padding-top: rem(16);
        padding-bottom: rem(15);

        width: 100%;

        position: relative;

        .page-padding();

        @{desktop} & {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            padding-top: rem(20);
            padding-bottom: rem(16);

            width: var(--desktop-width);
        }

        &_column {
            --picture-wrapper-width: rem(118);
            --picture-height: rem(118);
            flex-flow: column nowrap;
            align-items: center;

            text-align: center;

            padding-bottom: rem(5);

            // height: rem(251);

            transition: box-shadow 0.2s;

            &:hover {
                background: @color-white;
                box-shadow: @menu-desktop-box-shadow;

                z-index: 1;

                transition: box-shadow 0.2s;
            }

            @{desktop} & {
                justify-content: flex-start;

                padding-bottom: rem(22);

                height: initial;
            }
        }

        &_column:hover .block-shop__name,
        &_column:focus .block-shop__name {
            @media @hover {
                white-space: normal;
            }
        }

        &_column:hover .block-shop__content,
        &_column:focus .block-shop__content {
            @media @hover {
                white-space: normal;

                max-height: none;

                overflow: visible;
            }
        }
    }

    &__picture-wrapper {
        display: flex;
        justify-content: center;

        width: var(--picture-wrapper-width);

        position: relative;

        @media @media-less-375 {
            width: rem(75);
        }

        &_small {
            padding-right: rem(14);

            width: rem(65);

            @media @min375 {
                width: rem(86);
            }
        }

        @{desktop} & {
            align-items: center;

            height: rem(104);
            width: rem(104);
        }

        &_empty{
            @{mobile} &{
                background: #f4f4f4;
            }
        }

        .block-shop__wrapper_column & {
            height: var(--picture-height);
        }

        //@{desktop} & {
        //   width: rem(104)
        //}
    }

    &__picture {
        object-fit: contain;

        height: var(--picture-height);
        max-width: 100%;

        @{desktop} & {
            height: rem(104);
        }
    }

    &__rating {
        &.rating {
            --star-width: rem(15);
            --star-height: rem(13);

            top: rem(-2)
        }

        @{desktop} & {
            margin-top: rem(3);
        }
    }

    &__name {
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;

        max-width: rem(90);

        overflow: hidden;

        @media @min375 {
            max-width: rem(133);
        }

        .block-shop__wrapper_column & {
            font-size: rem(15);
            line-height: 1.5;

            margin-bottom: rem(2);
        }
    }

    &__caption {
        color: @color-gray;
        font-size: rem(14);
        text-overflow: ellipsis;
        white-space: nowrap;

        overflow: hidden;

        .block-shop__wrapper_column & {
            font-size: rem(13);
            line-height: 1.5;

            margin-bottom: rem(4);
        }
    }

    &__content {
        margin-top: rem(2);
        margin-right: auto;

        @{desktop} & {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &__inner-wrapper {
        padding: rem(5) 0 0;

        width: 65.5%;

        @{desktop} & {
            width: 100%;
        }

        &_content-centered {
            display: flex;
            align-items: center;
        }

        &_content-wide {
            margin-left: rem(5);

            width: 100%;
        }

        .block-shop__wrapper_column & {
            padding: rem(16) 0 0;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_column {
            flex-flow: column nowrap;
        }

        @{desktop} & {
            flex-direction: column;

            margin-top: 0;
        }
    }

    &__link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__description {
        font-size: rem(14);

        margin-top: rem(7);
    }

    &__comments-count-icon {
        height: rem(20);
        width: rem(20);

        transform: translateY(rem(-2));

        @{desktop} & {
            height: rem(14);
            width: rem(14);
        }
    }

    &__comments {
        display: flex;
        align-items: center;

        color: @color-gray-light;
        font-size: rem(13);
        font-weight: 500;

        margin-top: rem(15);

        &-caption {
            margin-left: rem(5)
        }

        @{desktop} & {
            margin-top: rem(9);
        }
    }
}
</style>
